import vue from 'vue';

// UTC = KST - 32400000ms // 32400000 = 1000ms * 60s * 60m * 9h // KST = UTC+9

const notices = [
  {
    id: 0,
    messages: [
      {
        locale: 'en',
        text:
          '[Notice] Lending, Rewards, Market service is restricted from Thu, Jan 21, 2021 01:30 to 03:30 (UTC) due to updating BiFi to lower gas fees.',
      },
      {
        locale: 'ko',
        text:
          '[공지] BiFi 가스비 절감을 위한 업데이트로 인하여 2021년 1월 21일 목요일 10:30 ~ 12:30 (KST) 까지 Lending, Rewards, Market 서비스 이용이 제한됩니다.',
      },
    ],
    conditions: [],
    term: {
      // eventStart: 1611160200000, // Thu Jan 21 2021 01:30:00 (UTC)
      // eventEnd: 1611167400000 // Thu Jan 21 2021 03:30:00 (UTC)
      from: 1611094649000, // Thu Jan 20 2021 07:17:29 (UTC)
      to: 1611167400000, // Thu Jan 21 2021 03:30:00 (UTC)

      // KST
      // // eventStart: 1611192600000, // Thu Jan 21 2021 10:30:00 (KST)
      // // eventEnd: 1611199800000 // Thu Jan 21 2021 12:30:00 (KST)
      // from: 1611127049000, // Thu Jan 20 2021 16:17:29 (KST)
      // to: 1611199800000 // Thu Jan 21 2021 12:30:00 (KST)
    },
    enabled: false,
  },
  {
    id: 1,
    messages: [
      {
        locale: 'en',
        text:
          'Hurry, We’re Giving Out $100,000 in BFC! Deposit for 45 Days and Earn Interest, BiFi, PLUS 1% Back in BFC {link0}',
        link: {
          id: 'link0',
          text: '[Learn More]',
          href: 'https://bifi-lending.medium.com/7eb5b8822274',
        },
      },
      {
        locale: 'ko',
        text:
          '지금 BiFi에 예금하면 1억원 상당의 BFC를 쏩니다! 45일 홀딩으로 BFC, BiFi, 예금 이자까지 모두 받을 수 있는 기회! {link0}',
        link: {
          id: 'link0',
          text: '[이벤트 알아보기]',
          href: 'https://bifi-lending.medium.com/ca09207e7f0d',
        },
      },
    ],
    conditions: [],
    term: {
      from: 1614194358038, //
      to: 1616723708286, //
    },
    enabled: false,
  },
  {
    id: 2,
    messages: [
      {
        locale: 'en',
        text:
          'Welcome to the new BiFi on Binance Smart Chain! Don’t forget to select the correct network on your MetaMask. {link0}',
        link: {
          id: 'link0',
          text: '[See how to add BSC Mainnet]',
          href:
            'https://bifi-lending.medium.com/7f22809f9955?source=friends_link&sk=868df32b84daea9a79ad51f3171fae70',
        },
      },
      {
        locale: 'ko',
        text:
          '새로운 Binance Smart Chain의 BiFi에 오신 것을 환영합니다! 잊지말고 메타마스크에서 네트워크를 설정해주세요. {link0}',
        link: {
          id: 'link0',
          text: '[설정하는 법 보기]',
          href:
            'https://bifi-lending.medium.com/ca3e467539b2?source=friends_link&sk=fb9d50be5e405f6a9261269245df134e',
        },
      },
    ],
    conditions: [store => store.getters.onlyAllowedNetworkType === 'bsc'],
    term: {
      from: 1614194358038, //
      to: 1616723708286, //
    },
    enabled: false,
  },
  {
    id: 3,
    messages: [
      {
        locale: 'en',
        text:
          'New BiFi X feature is here! Get up to 10X leverage on yield farming and trading on BiFi. {link0}',
        link: {
          id: 'link0',
          text: '[Try BiFi X]',
          href:
            'https://x.bifi.finance/?utm_source=bifi_app&utm_campaign=banner_1',
        },
      },
      {
        locale: 'ko',
        text:
          'BiFi X 서비스가 출시했습니다! BiFi기반 이자농사와 트레이딩에서 최대 10X 레버리지를 누려보세요 {link0}',
        link: {
          id: 'link0',
          text: '[BiFi X 시작하기]',
          href:
            'https://x.bifi.finance/?utm_source=bifi_app&utm_campaign=banner_1',
        },
      },
      {
        locale: 'zh-cn',
        text:
          'BiFi-X正式上线! 开启即可在基于BiFi的收益耕种、交易享受10倍杠杆率{link0}',
        link: {
          id: 'link0',
          text: '[开启BiFi-X]',
          href:
            'https://x.bifi.finance/?utm_source=bifi_app&utm_campaign=banner_1',
        },
      },
      {
        locale: 'zh-tw',
        text:
          'BiFi-X正式上線！開啟即可在基於BiFi的收益耕種、交易享受10倍杠杆率{link0}',
        link: {
          id: 'link0',
          text: '[開啟BiFi-X]',
          href:
            'https://x.bifi.finance/?utm_source=bifi_app&utm_campaign=banner_1',
        },
      },
    ],
    conditions: [],
    term: {
      from: 1625165027350, //
      to: 1630349027350, //
    },
    enabled: false,
  },
  {
    id: 4,
    messages: [
      {
        locale: 'en',
        text: 'The symbol of klaytn chain has been changed.',
      },
      {
        locale: 'ko',
        text: 'Klaytn chain의 symbol이 변경되었습니다.',
      },
    ],
    conditions: [store => store.getters.walletNetworkArea === 'klaytn'],
    term: {
      // ~= 2weeks
      from: 1656453123166, // Wed Jun 29 2022
      to: 1657662723166, // Wed Jul 13 2022
    },
    enabled: true,
  },
];

vue.prototype.$notices = notices;

export default notices;
